import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { CookiesProvider } from 'react-cookie';

import { Layout } from './src/components/Layout/Layout';
import { PromotionProvider } from './src/context/PromotionContext';
import { FormModalProvider } from './src/context/FormModalContext';
import { FormProvider } from './src/context/FormContext';
import { IndustrySearchProvider } from './src/context/IndustrySearchContext';

import { FaqSearchProvider } from './src/context/FaqSearchContext';
import { FeatureThumbnailProvider } from './src/context/FeatureThumbnailContext';
import { PromotionModalProvider } from './src/context/PromotionModalContext';
import { PardotProvider } from './src/context/PardotContext';

const INTERCOM_APP_ID = 'vqczfn1v';

export function wrapPageElement({ element, props }) {
	return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
	return (
		<CookiesProvider>
			<PardotProvider>
				{/* <LiveChatLoaderProvider
					providerKey={INTERCOM_APP_ID}
					provider='intercom'> */}
				<PromotionModalProvider>
					<FaqSearchProvider>
						<FeatureThumbnailProvider>
							<IndustrySearchProvider>
								<FormProvider>
									<FormModalProvider>
										<PromotionProvider>{element}</PromotionProvider>
									</FormModalProvider>
								</FormProvider>
							</IndustrySearchProvider>
						</FeatureThumbnailProvider>
					</FaqSearchProvider>
				</PromotionModalProvider>
				{/* </LiveChatLoaderProvider> */}
			</PardotProvider>
		</CookiesProvider>
	);
}
